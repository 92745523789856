import { Field } from '@components/fields';
import { FormItem } from '@components/forms';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { ContentWrapper } from '../controls';
import { bookingOrderDetailsBookingOrderSelector } from '../store/selectors';

export const BookingOrderDetailsCommentAdapter = () => {
  const bookingOrder = useSelector(bookingOrderDetailsBookingOrderSelector);

  if (!bookingOrder) {
    return null;
  }

  const cancelReasonName = bookingOrder.cancelReason?.name || bookingOrder.customerCancelReason?.name;
  const lastStatusComment = bookingOrder.lastStatusComment;

  return (
    <ContentWrapper>
      <FormItem title='Комментарий менеджера'>
        <Field label='Комментарий'>{bookingOrder.managerComment ?? '-'}</Field>
        {cancelReasonName && (
          <Field label='Причина отмены'>
            <Typography>{cancelReasonName}</Typography>
            {lastStatusComment && <Typography>{lastStatusComment}</Typography>}
          </Field>
        )}
      </FormItem>
    </ContentWrapper>
  );
};
