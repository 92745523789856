import { UserAccessPartitionBookingOrders, UUID } from '@/domain';
import MasterContentGridLayout from '@layouts/masterContentGrid/container';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../general/decorators/preloader';
import MasterDetailsContainer from '../../general/master/details/container';
import { BookingOrderLifeCycle } from '../lifecycle/types';
import { BookingOrderDetailsCommentAdapter } from './adapters/comment';
import { BookingOrderDetailsDetailsInfoAdapter } from './adapters/detailsInfo';
import BookingOrderDetailsFooterAdapter from './adapters/footer';
import BookingOrderDetailsHeaderAdapter from './adapters/header';
import { BookingOrderDetailsItemsTableAdapter } from './adapters/itemsTable';
import BookingOrderDetailsStepperAdapter from './adapters/stepper';
import { StepWrapper } from './controls';

import { BookingOrderDetailsDialogsAdapter } from './dialogs';
import { BookingOrderDetailsInitializer } from './initializer';
import {
  BookingOrderDetailHandlersProvider,
  BookingOrderDetailParamsProvider,
  BookingOrderDetailsLifecycleProvider,
} from './providers';
import { bookingOrderDetailsIsPreloadingSelector } from './store/selectors';

export interface BookingOrderDetailsContainerProps {
  readonly id: UUID;
  readonly lifecycle: BookingOrderLifeCycle;
  readonly access: UserAccessPartitionBookingOrders;
  readonly onClose: () => void;
}

const BookingOrderDetailsContainer = (props: BookingOrderDetailsContainerProps) => {
  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: BookingOrderDetailsStepperAdapter,
        header: BookingOrderDetailsHeaderAdapter,
        footer: BookingOrderDetailsFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={bookingOrderDetailsIsPreloadingSelector}
          />,
        ],
      }}
      initializer={BookingOrderDetailsInitializer}
      providers={[
        {
          name: 'params',
          factory: BookingOrderDetailParamsProvider,
        },
        {
          name: 'handlers',
          factory: BookingOrderDetailHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: BookingOrderDetailsLifecycleProvider,
        },
      ]}
      commonProps={props}
    >
      <MasterContentGridLayout>
        <StepWrapper>
          <BookingOrderDetailsDetailsInfoAdapter />
          <BookingOrderDetailsItemsTableAdapter />
          <BookingOrderDetailsCommentAdapter />
        </StepWrapper>
      </MasterContentGridLayout>

      <BookingOrderDetailsDialogsAdapter />
    </MasterDetailsContainer>
  );
};

export default BookingOrderDetailsContainer;
