import { BookingDateSlot, EServiceOrderByDateType, Nullable } from '@/domain';
import { FieldsGroupWrapper } from '@features/bookingOrder/edit/adapters/controls';
import { FormHelperText } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import BookingOrderEditItemInfoDialog from '../../components/editItemInfoDialog';
import BookingOrderItemsTable from '../../components/itemsTable';
import { BookingOrderEditCostField } from '../fields/cost';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bookingOrderEditBookingOrderCostSelector,
  bookingOrderEditBookingOrderItemsSelector,
  bookingOrderEditBookingOrderObjectNameSelector,
  bookingOrderEditValidationOrderItemsResultsSelector,
  bookingOrderEditValidationResultsByFieldSelector,
} from '../store/selectors';
import { BookingOrderEditFieldAdapter } from './field';
import FormItemAdapter from './formItem';

interface QuantityEditInfo {
  readonly orderByDateType: EServiceOrderByDateType;
  readonly qty?: Nullable<number>;
  readonly items?: BookingDateSlot[];
}

export const BookingOrderEditItemsTableAdapter = () => {
  const handlers = useContextHandlers();

  const [orderItemIndex, setOrderItemIndex] = useState<Nullable<number>>(null);

  const items = useSelector(bookingOrderEditBookingOrderItemsSelector);
  const objectName = useSelector(bookingOrderEditBookingOrderObjectNameSelector);

  const validation = useSelector(bookingOrderEditValidationResultsByFieldSelector('orderItems'));
  const validationItems = useSelector(bookingOrderEditValidationOrderItemsResultsSelector);

  const quantityEditInfo = useMemo<Nullable<QuantityEditInfo>>(() => {
    if (orderItemIndex === null) {
      return null;
    } else {
      const orderItem = items[orderItemIndex];
      if (orderItem?.priceItem.service.orderByDateType) {
        switch (orderItem.priceItem.service.orderByDateType) {
          case EServiceOrderByDateType.Period:
          case EServiceOrderByDateType.Days:
            return { orderByDateType: orderItem.priceItem.service.orderByDateType, items: orderItem.slots ?? [] };
          case EServiceOrderByDateType.None:
            return {
              orderByDateType: orderItem.priceItem.service.orderByDateType,
              qty: orderItem.qty,
            };
        }
      } else return null;
    }
  }, [orderItemIndex, items]);

  const onApplyEditInfo = useCallback(
    (orderInfo: QuantityEditInfo) => {
      if (orderItemIndex !== null) {
        switch (orderInfo.orderByDateType) {
          case EServiceOrderByDateType.Period:
          case EServiceOrderByDateType.Days:
            handlers.onChangeOrderItemAttribute(orderItemIndex, 'slots', orderInfo.items ?? null);
            setOrderItemIndex(null);
            break;
          case EServiceOrderByDateType.None:
            handlers.onChangeOrderItemAttribute(orderItemIndex, 'qty', orderInfo.qty ?? 0);
            setOrderItemIndex(null);
            break;
        }
      }
    },
    [orderItemIndex]
  );

  return (
    <FormItemAdapter title={`Состав заказа по объекту «${objectName ?? ''}»`}>
      <FieldsGroupWrapper>
        <BookingOrderItemsTable
          items={items}
          validation={validationItems}
          onChangeAttribute={handlers.onChangeOrderItemAttribute}
          onDelete={handlers.onDeleteOrderItem}
          onTryChangeQuantity={value => setOrderItemIndex(value)}
        />
        <div>
          <MPButton
            variant='outlined'
            fullWidth={false}
            onClick={handlers.onTryAddService}
          >
            Добавить услугу
          </MPButton>
        </div>
        <BookingOrderEditFieldAdapter
          name={'cost'}
          component={BookingOrderEditCostField}
          valueSelector={bookingOrderEditBookingOrderCostSelector}
        />
        {validation?.hasError && <FormHelperText error>{validation.message}</FormHelperText>}
        {quantityEditInfo && (
          <BookingOrderEditItemInfoDialog
            bookingInfo={quantityEditInfo}
            onApply={onApplyEditInfo}
            onClose={() => setOrderItemIndex(null)}
          />
        )}
      </FieldsGroupWrapper>
    </FormItemAdapter>
  );
};
