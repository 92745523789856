import { EPartnerOwnershipType, ETaxationSystemType, PartnerDraft, SportOptionTyped } from '@/domain';
import { SideColumn } from '@layouts/masterContentGrid/controls';
import { EPartnerStep } from '../../general/partner/types';
import PartnerApplicationViewStepAddress from '../components/steps/address/view';
import PartnerApplicationViewStepDetails from '../components/steps/details/view';
import PartnerApplicationViewStepDocuments from '../components/steps/documents/view';
import PartnerApplicationViewStepGeneral from '../components/steps/general/view';
import { PartnerApplicationDetailsSidePanelsAdapter } from './adapters/sidePanels';
import { ColumnWrapper, Content, Wrapper } from './controls';

interface PartnerApplicationDetailsProps {
  readonly partnerApplication: PartnerDraft;
  readonly step: EPartnerStep;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
}

const PartnerApplicationDetails = (props: PartnerApplicationDetailsProps) => {
  const { partnerApplication, step, ownerShipTypes, taxationSystemTypes } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        {step === EPartnerStep.General && (
          <Content>
            <PartnerApplicationViewStepGeneral
              partnerApplication={partnerApplication}
              ownerShipTypes={ownerShipTypes}
            />
          </Content>
        )}
        {step === EPartnerStep.Address && (
          <Content>
            <PartnerApplicationViewStepAddress
              address={partnerApplication.address}
              orgType={partnerApplication.regInfo?.orgType ?? null}
            />
          </Content>
        )}
        {step === EPartnerStep.Details && (
          <Content>
            <PartnerApplicationViewStepDetails
              partnerApplication={partnerApplication}
              taxationSystemTypes={taxationSystemTypes}
            />
          </Content>
        )}
        {step === EPartnerStep.Documents && (
          <Content>
            <PartnerApplicationViewStepDocuments partnerApplication={partnerApplication} />
          </Content>
        )}
      </ColumnWrapper>

      <SideColumn sideContentPosition={'fixed'}>
        <PartnerApplicationDetailsSidePanelsAdapter />
      </SideColumn>
    </Wrapper>
  );
};

export default PartnerApplicationDetails;
