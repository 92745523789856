import { PartnerDesk } from '@/domain';
import { Grid, Link, Typography } from '@mui/material';
import React from 'react';
import FileInfo from '../../../../../components/common/files/info';
import Splitter from '../../../../../components/common/splitter';
import DangerouslyHtmlWrapper from '../../../../../components/common/wrappers/dangerouslyHtml';
import { AddressHelper } from '../../../../../utils/address';

interface PartnerDetailsTabDeskProps {
  readonly desk: PartnerDesk;
}

const PartnerDetailsTabDesk = ({ desk }: PartnerDetailsTabDeskProps) => {
  const { aboutCompany, contacts, image } = desk;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
      flexWrap='nowrap'
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant='subtitle2'
          gutterBottom
        >
          Логотип
        </Typography>
        {image?.path && <FileInfo id={image.path} />}
        {!image?.path && <Typography color='error'>файл не загружен</Typography>}
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Splitter variant='horizontal' />
        <Typography
          variant='subtitle2'
          gutterBottom
        >
          О компании
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Описание о компании
        </Typography>
        <DangerouslyHtmlWrapper>
          <Typography dangerouslySetInnerHTML={{ __html: aboutCompany }} />
        </DangerouslyHtmlWrapper>
      </Grid>

      {contacts?.map((contact, index) => (
        <React.Fragment key={index}>
          <Grid
            item
            xs={12}
          >
            <Splitter variant='horizontal' />
            <Typography
              variant='subtitle2'
              gutterBottom
            >
              Контакт {contacts?.length > 1 ? index + 1 : ''}
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              Название контакта
            </Typography>
            <Typography>{contact.name ?? '-'}</Typography>
          </Grid>

          {contact.address && (
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='body2'
                color='textSecondary'
              >
                Область / Республика / Край
              </Typography>
              <Typography>{new AddressHelper(contact.address).getLocalityRegion() ?? '-'}</Typography>
            </Grid>
          )}

          {contact.address && (
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='body2'
                color='textSecondary'
              >
                Адрес
              </Typography>
              <Typography>{new AddressHelper(contact.address).getFullPathWithPostalCode() ?? '-'}</Typography>
            </Grid>
          )}

          {contact.phone && (
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='body2'
                color='textSecondary'
              >
                Телефон
              </Typography>
              <Typography
                component={Link}
                href={`tel:${contact.phone}`}
                color='textPrimary'
              >
                {contact.phone}
              </Typography>
            </Grid>
          )}

          {contact.email && (
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='body2'
                color='textSecondary'
              >
                Электронная почта
              </Typography>
              <Typography
                component={Link}
                href={`mailto:${contact.email}`}
                color='textPrimary'
              >
                {contact.email}
              </Typography>
            </Grid>
          )}
          {contact.feedbackLink && (
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='body2'
                color='textSecondary'
              >
                Ссылка на форму обратной связи
              </Typography>
              <Typography
                component={Link}
                href={contact.feedbackLink}
                target='_blank'
                color='textPrimary'
              >
                {contact.feedbackLink}
              </Typography>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};
export default PartnerDetailsTabDesk;
