import EntityMasterInfoPanelItem from '@components/common/entityMaster/infoPanel/item';
import { SidePanelContentWrapper } from '@components/common/wrappers/sidePanel';
import usePartnerInfoPanels from '@features/general/partner/hooks/useInfoPanels';
import { SupportMPInfoPanelContainer } from '@features/support/infoPanel';
import { useSelector } from 'react-redux';
import { PartnerApplicationDetailsDataSelector } from '../store/selectors';

export const PartnerApplicationDetailsSidePanelsAdapter = () => {
  const partner = useSelector(PartnerApplicationDetailsDataSelector);

  const infoPanels = usePartnerInfoPanels({
    partner,
  });

  return (
    <SidePanelContentWrapper>
      {infoPanels?.map((infoPanel, index) => (
        <EntityMasterInfoPanelItem
          key={index}
          infoPanel={infoPanel}
        />
      ))}
      <SupportMPInfoPanelContainer />
    </SidePanelContentWrapper>
  );
};
