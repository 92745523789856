import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  height: 100%;
  overflow-x: auto;
`;

export const ColumnContent = styled.div`
  height: 100%;
`;

interface SideColumnProps {
  readonly sideContentPosition: 'sticky' | 'fixed';
}

export const SideColumn = styled.div<SideColumnProps>(
  ({ theme, sideContentPosition }) => css`
    width: 50%;
    height: 100%;

    padding-top: ${theme.spacing(2)};

    > * {
      position: ${sideContentPosition};
      z-index: 3;
    }
  `
);
