import { RootState } from '@/data/store/store';
import { ESortDirection, Nullable, TradeOffer } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';
import { ETradeOfferTableColumn } from '../../types';

export const tradeOffersTradeOffersSelector = (store: RootState) => store.tradeOffer.list.data;
export const tradeOffersCountNeedRefreshWatcherSelector = (store: RootState) =>
  store.tradeOffer.list.allTradeOffers.needRefreshWatcher;
export const tradeOffersIsEmptySelector = (store: RootState) =>
  store.tradeOffer.list.allTradeOffers.isFetched && store.tradeOffer.list.allTradeOffers.count === 0;
export const tradeOffersIsPreloadingSelector = (store: RootState) =>
  store.tradeOffer.list.allTradeOffers.count === null;
export const tradeOffersPageNumberSelector = (store: RootState) => store.tradeOffer.list.pageNumber;
export const tradeOffersTotalCountSelector = (store: RootState) => store.tradeOffer.list.totalCount;
export const tradeOffersPageCountSelector = (store: RootState) => store.tradeOffer.list.pageCount;
export const tradeOffersSearchSelector = (store: RootState) => store.tradeOffer.list.search;
export const tradeOffersSortSelector = (store: RootState) => store.tradeOffer.list.search.sort;
export const tradeOffersSortColumnSelector = (store: RootState) =>
  (store.tradeOffer.list.search.sort?.split(',') as [ETradeOfferTableColumn, ESortDirection])?.[0] ?? '';
export const tradeOffersSortDirectionSelector = (store: RootState) =>
  (store.tradeOffer.list.search.sort?.split(',') as [ETradeOfferTableColumn, ESortDirection])?.[1] ?? '';
export const tradeOffersFilterSelector = (store: RootState) => store.tradeOffer.list.filter;
export const tradeOffersIsFetchingSelector = (store: RootState) =>
  store.tradeOffer.list.isFetching || store.tradeOffer.list.allTradeOffers.isFetching;

export const tradeOffersGuidSelector = (store: RootState) => store.tradeOffer.list.guid;
export const tradeOffersNotifierInfoGet = (store: RootState) => store.tradeOffer.list.notifierInfo;
export const tradeOffersNeedRefreshWatcherSelector = (store: RootState) => store.tradeOffer.list.needRefreshWatcher;
export const tradeOffersTabsCounterSelector = (store: RootState) => store.tradeOffer.list.tabsCounter;
export const tradeOffersTabsCounterUnwatchedSelector = (store: RootState) => store.tradeOffer.list.tabsCounterUnwatched;
export const tradeOffersUnwatchedIdsSelector = (store: RootState) => store.tradeOffer.list.unwatchedIds;

export const tradeOffersTradeOfferIndexSelector = (store: RootState, index: number) => index;
const createTradeOffersTableTradeOfferByIndexSelector = createSelector(
  tradeOffersTradeOffersSelector,
  tradeOffersTradeOfferIndexSelector,
  (tradeOffers, index): Nullable<TradeOffer> => tradeOffers?.[index] ?? null
);
const createTradeOffersTableUnwatchedByIndexSelector = createSelector(
  tradeOffersTradeOffersSelector,
  tradeOffersUnwatchedIdsSelector,
  tradeOffersTradeOfferIndexSelector,
  (tradeOffers, unwatchedIds, index): boolean => {
    const tradeOffer = tradeOffers?.[index] ?? null;
    if (!tradeOffer) {
      return false;
    } else {
      return unwatchedIds?.includes(tradeOffer.id) ?? false;
    }
  }
);
export const tradeOffersTradeOfferByIndexSelector = (index: number) => (store: RootState) =>
  createTradeOffersTableTradeOfferByIndexSelector(store, index);
export const tradeOffersUnwatchedByIndexSelector = (index: number) => (store: RootState) =>
  createTradeOffersTableUnwatchedByIndexSelector(store, index);
