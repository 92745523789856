import { MenuSvgIcon } from '@components/common/images/icon';
import styled from '@emotion/styled';
import { Badge } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  ApartmentIcon,
  ChatIcon,
  ComplaintIcon,
  EventIcon,
  FlagIcon,
  GiftIcon,
  PieChartIcon,
  PlaygroundIcon,
  ProfileIcon,
  RepeatIcon,
  SettingsIcon,
  SiteManagementIcon,
  SlidersIcon,
  StarIcon,
  SubmenuIcon,
  TeamIcon,
  UserIcon,
  UsersIcon,
} from './icons';
import theme from './theme';
import { MenuPanelItem, MenuPanelLink } from './types';

const CompanyWrapper = styled(Badge)`
  .MuiBadge-badge {
    transform: translate(60%, -80%);
  }
`;

type CommonMenuType = {
  readonly offers: MenuPanelItem;
  readonly productOffers: (link: MenuPanelLink) => MenuPanelItem;
  readonly tradeOffers: (link: MenuPanelLink) => MenuPanelItem;
  readonly corpOffers: (link: MenuPanelLink) => MenuPanelItem;
  readonly bookingOffers: (link: MenuPanelLink) => MenuPanelItem;

  readonly orders: MenuPanelItem;
  readonly productOfferOrders: (link: MenuPanelLink) => MenuPanelItem;
  readonly bookingOfferOrders: (link: MenuPanelLink) => MenuPanelItem;

  readonly company: (isRejected: boolean) => MenuPanelItem;
  readonly partnerCompany: (link: MenuPanelLink) => MenuPanelItem;
  readonly partnerEmployees: (link: MenuPanelLink) => MenuPanelItem;

  readonly clientOrgs: (link: MenuPanelLink) => MenuPanelItem;
  readonly clientOrgUsers: (link: MenuPanelLink) => MenuPanelItem;
  readonly clientOrgDetails: (link: MenuPanelLink) => MenuPanelItem;

  readonly activations: MenuPanelItem;
  readonly tradeActivations: (link: MenuPanelLink) => MenuPanelItem;
  readonly corpActivations: (link: MenuPanelLink) => MenuPanelItem;

  readonly analytics: MenuPanelItem;
  readonly queryAnalytics: (link: MenuPanelLink) => MenuPanelItem;
  readonly reports: (link: MenuPanelLink) => MenuPanelItem;

  readonly users: MenuPanelItem;
  readonly socialPackages: (link: MenuPanelLink) => MenuPanelItem;
  readonly partners: (link: MenuPanelLink) => MenuPanelItem;
  readonly customers: (link: MenuPanelLink) => MenuPanelItem;
  readonly mpAdmins: (link: MenuPanelLink) => MenuPanelItem;

  readonly configuration: (link: MenuPanelLink) => MenuPanelItem;

  readonly ads: MenuPanelItem;
  readonly landingWindow: (link: MenuPanelLink) => MenuPanelItem;
  readonly partnerWindow: (link: MenuPanelLink) => MenuPanelItem;
  readonly tradeOfferBanners: (link: MenuPanelLink) => MenuPanelItem;
  readonly corpOfferBanners: (link: MenuPanelLink) => MenuPanelItem;
  readonly adCampaigns: (link: MenuPanelLink) => MenuPanelItem;

  readonly mpSettings: MenuPanelItem;
  readonly mpConfiguration: (link: MenuPanelLink) => MenuPanelItem;
  readonly support: (link: MenuPanelLink) => MenuPanelItem;

  readonly customerProfile: (link: MenuPanelLink) => MenuPanelItem;

  readonly events: (link: MenuPanelLink) => MenuPanelItem;
  readonly playgrounds: (link: MenuPanelLink) => MenuPanelItem;
  readonly sportsmen: (link: MenuPanelLink) => MenuPanelItem;
  readonly teams: (link: MenuPanelLink) => MenuPanelItem;
  readonly chats: (link: MenuPanelLink) => MenuPanelItem;
  readonly complaints: (link: MenuPanelLink) => MenuPanelItem;
  readonly sportReports: (link: MenuPanelLink) => MenuPanelItem;
};

export const commonMenu: CommonMenuType = {
  mpSettings: {
    id: uuidv4(),
    caption: 'Настройки',
    icon: <MenuSvgIcon icon={SettingsIcon} />,
    link: '',
  },
  mpConfiguration: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Настройки сервиса',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  support: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Служба поддержки',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  orders: {
    id: uuidv4(),
    caption: 'Заказы',
    icon: <MenuSvgIcon icon={RepeatIcon} />,
    link: '',
  },
  productOfferOrders: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Товары',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  bookingOfferOrders: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Досуг и отдых',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  ads: {
    id: uuidv4(),
    caption: 'Продвижение',
    icon: <MenuSvgIcon icon={StarIcon} />,
    link: '',
  },
  landingWindow: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Главная страница',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  partnerWindow: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Страница компании',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  tradeOfferBanners: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Рекламные баннеры ТП',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  corpOfferBanners: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Рекламные баннеры КП',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  adCampaigns: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Рекламный кабинет',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  company: isRejected => ({
    id: uuidv4(),
    caption: 'Компания',
    icon: (
      <CompanyWrapper
        variant='dot'
        color='error'
        invisible={!isRejected}
      >
        <MenuSvgIcon icon={FlagIcon} />
      </CompanyWrapper>
    ),
    link: '',
  }),
  partnerCompany: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Данные компании',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  partnerEmployees: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Сотрудники',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  users: {
    id: uuidv4(),
    caption: 'Пользователи',
    icon: <MenuSvgIcon icon={UsersIcon} />,
    link: '',
  },
  socialPackages: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'КСП',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  partners: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Партнёры',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  customers: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Покупатели',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  mpAdmins: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Администраторы',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),

  clientOrgs: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Компании-клиенты',
    icon: <MenuSvgIcon icon={ApartmentIcon} />,
    link,
  }),
  clientOrgUsers: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Пользователи',
    icon: <MenuSvgIcon icon={UsersIcon} />,
    link,
  }),
  clientOrgDetails: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Настройка Витрины',
    icon: <MenuSvgIcon icon={SettingsIcon} />,
    link,
  }),

  configuration: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Настройки сервиса',
    icon: <MenuSvgIcon icon={SlidersIcon} />,
    link,
  }),

  customerProfile: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Профиль покупателя',
    icon: <MenuSvgIcon icon={UserIcon} />,
    link,
  }),

  activations: {
    id: uuidv4(),
    caption: 'Активации',
    icon: <MenuSvgIcon icon={GiftIcon} />,
    link: '',
  },
  tradeActivations: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Торговые',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  corpActivations: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Корпоративные',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),

  analytics: {
    id: uuidv4(),
    caption: 'Аналитика',
    icon: <MenuSvgIcon icon={PieChartIcon} />,
    link: '',
  },
  reports: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Отчеты',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  queryAnalytics: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Поисковые запросы',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),

  offers: {
    id: uuidv4(),
    caption: 'Предложения',
    icon: <MenuSvgIcon icon={SiteManagementIcon} />,
    link: '',
  },
  tradeOffers: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Торговые',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  corpOffers: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Корпоративные',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  productOffers: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Товарные',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  bookingOffers: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Досуг и отдых',
    icon: (
      <MenuSvgIcon
        htmlColor={theme.palette.text.secondary}
        icon={SubmenuIcon}
      />
    ),
    link,
  }),
  events: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Мероприятия',
    icon: <MenuSvgIcon icon={EventIcon} />,
    link,
  }),
  sportsmen: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Пользователи',
    icon: <MenuSvgIcon icon={ProfileIcon} />,
    link,
  }),
  teams: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Команды',
    icon: <MenuSvgIcon icon={TeamIcon} />,
    link,
  }),
  playgrounds: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Площадки',
    icon: <MenuSvgIcon icon={PlaygroundIcon} />,
    link,
  }),
  chats: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Чаты',
    icon: <MenuSvgIcon icon={ChatIcon} />,
    link,
  }),
  complaints: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Жалобы',
    icon: <MenuSvgIcon icon={ComplaintIcon} />,
    link,
  }),
  sportReports: (link: MenuPanelLink) => ({
    id: uuidv4(),
    caption: 'Отчеты',
    icon: <MenuSvgIcon icon={PieChartIcon} />,
    link,
  }),
};
