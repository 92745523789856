import { AppUser, EPartnerStatus, EUserRole } from '@/domain';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { Divider, ListItemText } from '@mui/material';
import { ETagColors, MPTag } from '@ui-kit/tag';
import privilegeIcon from '../../../../../icons/privilegeIcon.png';
import { getUserRoleName } from '../../../../../utils/roles';
import { HeaderListItem, MPRoleName, PrivilegeIcon } from './controls';

interface MainMenuHeaderPartnerProps {
  readonly user: AppUser;
}

const MainMenuHeaderPartner = ({ user }: MainMenuHeaderPartnerProps) => {
  const { mpPartnerUserProfile } = useCurrentUser();

  return (
    <>
      <HeaderListItem>
        <ListItemText
          primary={<PrivilegeIcon src={privilegeIcon} />}
          secondary={<MPRoleName>{getUserRoleName((user.realm_access?.roles ?? []) as EUserRole[])}</MPRoleName>}
        />
        {mpPartnerUserProfile?.partner?.status === EPartnerStatus.Rejected && (
          <ListItemText
            style={{ marginTop: 0 }}
            primary={
              <MPTag
                dense
                variant='filled'
                color={ETagColors.Warning}
                label='Заявка на партнерство отклонена'
              />
            }
          />
        )}
      </HeaderListItem>
      <Divider />
    </>
  );
};

export default MainMenuHeaderPartner;
