import { ContentLimiter } from '@components/common/contentLimit';
import { ContentWrapper } from '@features/bookingOrder/edit/adapters/controls';
import { ContentLimiterHintLayout } from '../../../../components/common/contentLimit/layouts';
import { BookingOrderEditManagerCommentField } from '../fields/managerComment';
import { bookingOrderEditBookingOrderManagerCommentSelector } from '../store/selectors';
import { BookingOrderEditFieldAdapter } from './field';
import FormItemAdapter from './formItem';

const descriptionText = 'Чтобы изменить статус заказа, сначала выберите статус для каждой услуги';

export const BookingOrderEditStatusSectionAdapter = () => {
  return (
    <FormItemAdapter title='Комментарий менеджера'>
      <ContentWrapper>
        <ContentLimiter
          layout={ContentLimiterHintLayout}
          layoutProps={{ collapsedTitle: descriptionText, expandedTitle: descriptionText }}
        >
          <BookingOrderEditFieldAdapter
            name={'managerComment'}
            component={BookingOrderEditManagerCommentField}
            valueSelector={bookingOrderEditBookingOrderManagerCommentSelector}
          />
        </ContentLimiter>
      </ContentWrapper>
    </FormItemAdapter>
  );
};
