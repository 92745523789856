import { EPartnerStatus, PartnerShort } from '@/domain';
import { MPStepperStep } from '@components/common/stepper';
import { EPartnerInfoPanel } from '@features/general/partner/enums';
import { PanelAction, PanelActions } from '../../../types';

export enum EPartnerStep {
  General = 'general',
  Address = 'address',
  Details = 'details',
  Documents = 'documents',
  Permissions = 'permissions',
}

export type PartnerStep = MPStepperStep<EPartnerStep>;

export enum EPartnerActionType {
  Save = 'save',
  Edit = 'edit',
  EditDesk = 'edit_desk',
  Activate = 'activate',
  Deactivate = 'deactivate',
  ChangeSortIndex = 'changeSortIndex',
  ReturnToVerification = 'returnToVerification',
  Delete = 'delete',
}

export type PartnerActionCall<T extends PartnerShort> = {
  readonly action: EPartnerActionType.ChangeSortIndex;
  readonly partner: T;
  readonly sortIndex: number;
};

export interface PartnerActionProcess<Type = EPartnerActionType> {
  readonly isFetching: boolean;
  readonly type: Type;
}

export type PartnerActionDetailsType = Exclude<
  EPartnerActionType,
  EPartnerActionType.Save | EPartnerActionType.ReturnToVerification | EPartnerActionType.Delete
>;

export type PartnerActionEditType = EPartnerActionType.Save;

export type PartnerActionTableType =
  | EPartnerActionType.Activate
  | EPartnerActionType.Deactivate
  | EPartnerActionType.ReturnToVerification
  | EPartnerActionType.Delete;

export type PartnerAction<A extends EPartnerActionType, P = any> = PanelAction<A, P>;

export type PartnerActions<A extends EPartnerActionType> = PanelActions<A>;

export enum EPartnerTableTab {
  Verification = 'verification',
  Active = 'active',
  Deactivated = 'deactivated',
  Rejected = 'rejected',
  Unverified = 'unverified',
}

export enum EPartnerTableColumn {
  SortIndex = 'sortIndex',
  Date = 'lastStatusDate',
  Name = 'name',
  OwnerName = 'owner.lastName',
  OwnerPhone = 'owner.phone',
  OwnerEmail = 'owner.email',
  Reviewer = 'partnerReviewer.name',
  Inn = 'inn',
  Kpp = 'kpp',
  Ogrn = 'ogrn',
  Actions = 'actions',
}

export interface GetPartnerActionsByPermissions {
  readonly canSave?: boolean;
  readonly canEdit?: boolean;
  readonly canEditDesk?: boolean;
  readonly canDelete?: boolean;
  readonly canActivate?: boolean;
  readonly canDeactivate?: boolean;
  readonly canReturnToVerification?: boolean;
}

export type PartnerCounterByStatus = {
  [name in EPartnerStatus]?: number;
};

export type PartnerInfoPanelType = EPartnerInfoPanel;
