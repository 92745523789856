import { Nullable } from '@/domain';
import { Grid, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import MenuButton from '../../../../components/common/buttons/menu';
import DefaultHeader from '../../../../components/common/header';
import { useContextHandlers } from '../hooks/useContextHandlers';

type BookingOrderTableHeaderAdapterProps = {
  readonly canDownload?: boolean;
};

const BookingOrderTableHeaderAdapter = ({ canDownload }: BookingOrderTableHeaderAdapterProps) => {
  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);

  const handlers = useContextHandlers();

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        <Grid
          container
          spacing={1}
          alignItems='center'
        >
          {canDownload && (
            <Grid item>
              <MenuButton
                variant='outlined'
                color='primary'
                id={uuidv4()}
                closeMarker={menuCloseMarker}
              >
                <MenuItem
                  onClick={() => {
                    setMenuCloseMarker(Symbol());
                    handlers.onOrdersTryDownload();
                  }}
                >
                  Выгрузить заказы досуга и отдыха
                </MenuItem>
              </MenuButton>
            </Grid>
          )}
        </Grid>
      }
    >
      <Typography variant='h2'>Заказы досуга и отдыха</Typography>
    </DefaultHeader>
  );
};

export default BookingOrderTableHeaderAdapter;
