import { ContentLimiter } from 'presentation/components/common/contentLimit';
import { ContentLimiterDialogLayout } from 'presentation/components/common/contentLimit/layouts';
import { Wrapper } from 'presentation/components/common/entityMaster/infoPanel/item/controls';
import SectionTitle from 'presentation/components/common/sectionTitle';
import { EntityInfoPanel } from 'presentation/types';

type EntityMasterInfoPanelItemProps<T extends string> = {
  readonly infoPanel: EntityInfoPanel<T>;
};

const EntityMasterInfoPanelItem = <T extends string>(props: EntityMasterInfoPanelItemProps<T>) => {
  const {
    infoPanel: { title, text },
  } = props;

  if (text?.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <SectionTitle gutterBottom={false}>{title}</SectionTitle>
      {text?.map((item, index) => (
        <ContentLimiter
          key={index}
          layout={ContentLimiterDialogLayout}
          layoutProps={{ title }}
        >
          {item}
        </ContentLimiter>
      ))}
    </Wrapper>
  );
};

export default EntityMasterInfoPanelItem;
