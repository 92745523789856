import { EOfferStatus, Nullable, UUID } from '@/domain';
import { Grid, MenuItem, Typography } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import MenuButton from '../../../../components/common/buttons/menu';
import FileDownloadLink from '../../../../components/common/files/download/link';
import DefaultHeader from '../../../../components/common/header';
import { offerExportAccept } from '../../../general/offer/constants';
import { EOfferTableTab } from '../../../general/offer/types';
import { getStatesFilterForOfferTableTab } from '../../../general/offer/utils/table';
import { getTradeOfferCreateRoute, getTradeOffersUploadRoute } from '../../entry';
import { Tooltip, TooltipTitleWrapper } from '../controls';
import { tradeOffersFilterSelector, tradeOffersSortSelector } from '../store/selectors';
import { getTradeOffersDownloadUrl } from '../utils';

interface TradeOffersTableHeaderAdapterProps {
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly createInfo?: Nullable<string>;
  readonly isCreateDisabled?: boolean;
  readonly canCreate?: boolean;
  readonly canDownload?: boolean;
  readonly canUpload?: boolean;
}

const getStatusesByTab = (t: EOfferTableTab) => {
  return getStatesFilterForOfferTableTab(t, [EOfferStatus.OnModeration, EOfferStatus.WaitingForModeration]);
};

const TradeOffersTableHeaderAdapter = (props: TradeOffersTableHeaderAdapterProps) => {
  const { tab, createInfo, isCreateDisabled, canCreate, canDownload, canUpload, partnerId } = props;

  const history = useHistory();

  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);

  const sort = useSelector(tradeOffersSortSelector);
  const filter = useSelector(tradeOffersFilterSelector);
  const currentStatuses = getStatusesByTab(tab);

  const onUpload = () => history.push(getTradeOffersUploadRoute());

  const onCreate = () => history.push(getTradeOfferCreateRoute({}));

  const downloadUrl = getTradeOffersDownloadUrl({
    sort,
    skipPageable: true,
    states: currentStatuses,
    partnerId,
    filter,
  });

  const showMenu = canDownload || canUpload;
  const showCreateButton = canCreate;

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        (showMenu || showCreateButton) && (
          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            {showMenu && (
              <Grid item>
                <MenuButton
                  variant='outlined'
                  color='primary'
                  id={uuidv4()}
                  closeMarker={menuCloseMarker}
                >
                  {canDownload && (
                    <MenuItem>
                      <FileDownloadLink
                        src={downloadUrl}
                        accept={offerExportAccept}
                        fileName={'торговые_предложения'}
                        component={'div'}
                        onDownload={() => setMenuCloseMarker(Symbol())}
                      >
                        Выгрузить торговые предложения
                      </FileDownloadLink>
                    </MenuItem>
                  )}
                  {canUpload && <MenuItem onClick={onUpload}>Загрузить торговые предложения</MenuItem>}
                </MenuButton>
              </Grid>
            )}
            {showCreateButton && (
              <Grid item>
                <Tooltip
                  arrow
                  placement='bottom-end'
                  open={!!createInfo}
                  title={
                    <TooltipTitleWrapper>
                      <Typography>{createInfo}</Typography>
                    </TooltipTitleWrapper>
                  }
                >
                  <MPButton
                    disabled={isCreateDisabled}
                    variant='outlined'
                    size='small'
                    onClick={onCreate}
                  >
                    Создать ТП
                  </MPButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )
      }
    >
      <Typography variant='h2'>Торговые предложения</Typography>
    </DefaultHeader>
  );
};

export default TradeOffersTableHeaderAdapter;
