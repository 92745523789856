import {
  EPartnerOwnershipType,
  EPartnerType,
  ETaxationSystemType,
  GlobalNotificationOption,
  NotificationOption,
  Nullable,
  Partner,
  PartnerDesk,
  SportOptionTyped,
} from '@/domain';
import { SideColumn } from '@layouts/masterContentGrid/controls';
import ConfigurationNotifications from '../../../configuration/components/notifications';
import PartnerDetailsTabDesk from '../../components/detailsTab/desk';
import PartnerDetailsTabLegal from '../../components/detailsTab/legal';
import { ColumnWrapper, Wrapper } from '../controls';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab, PartnerDetailsLegalTab } from '../utils';
import { PartnerDetailsAdminSidePanelsAdapter } from './adapters/sidePanels';
import PartnerDetailsAdminDeskStub from './deskStub';

interface PartnerDetailsAdminComponentProps {
  readonly tab: EPartnerDetailsTab;
  readonly legalTab: EPartnerDetailsLegalTab;
  readonly legalTabs: PartnerDetailsLegalTab[];
  readonly partner: Partner;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly desk: Nullable<PartnerDesk>;
  readonly notifications: NotificationOption[];
  readonly taxSystems: SportOptionTyped<ETaxationSystemType>[];
  readonly onChangeLegalTab: (tab: PartnerDetailsLegalTab) => void;
}

const PartnerDetailsAdminComponent = (props: PartnerDetailsAdminComponentProps) => {
  const {
    tab,
    legalTab,
    legalTabs,
    partner,
    partnerTypes,
    desk,
    notifications,
    taxSystems,
    ownerShipTypes,
    onChangeLegalTab,
  } = props;

  const notificationsData: GlobalNotificationOption[] = notifications.map(notification => {
    const enabled =
      partner.marketingDistributionSettings?.find(item => item.option.id === notification.id)?.enabled ?? false;

    return {
      ...notification,
      enabled,
    };
  });

  return (
    <Wrapper>
      <ColumnWrapper>
        {tab === EPartnerDetailsTab.Legal && (
          <PartnerDetailsTabLegal
            partner={partner}
            partnerTypes={partnerTypes}
            tabs={legalTabs}
            tab={legalTab}
            taxSystems={taxSystems}
            ownerShipTypes={ownerShipTypes}
            onChangeTab={onChangeLegalTab}
          />
        )}
        {tab === EPartnerDetailsTab.Desk && !desk && <PartnerDetailsAdminDeskStub />}
        {tab === EPartnerDetailsTab.Desk && desk && <PartnerDetailsTabDesk desk={desk} />}
        {tab === EPartnerDetailsTab.Notifications && (
          <ConfigurationNotifications
            notifications={notificationsData}
            onChange={null}
          />
        )}
      </ColumnWrapper>

      <SideColumn sideContentPosition={'fixed'}>
        <PartnerDetailsAdminSidePanelsAdapter />
      </SideColumn>
    </Wrapper>
  );
};

export default PartnerDetailsAdminComponent;
