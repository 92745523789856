import useBusinessConfig from '@/presentation/hooks/useBusinessConfig';
import { convertStringToArray } from '@/presentation/utils';
import SectionTitle from '@components/common/sectionTitle';
import Splitter from '@components/common/splitter';
import { Link, Typography } from '@mui/material';
import { Wrapper } from 'presentation/features/support/infoPanel/controls';

export const SupportMPInfoPanelContainer = () => {
  const { supportInfo } = useBusinessConfig();
  const emails = convertStringToArray(supportInfo.mp?.emails ?? '');
  const phones = convertStringToArray(supportInfo.mp?.phones ?? '');

  return (
    <Wrapper>
      <SectionTitle>Поддержка</SectionTitle>
      <Typography
        variant='body2'
        gutterBottom
      >
        Если у Вас возникли вопросы, обращайтесь в службу поддержки:
      </Typography>
      {phones.map(item => (
        <Typography
          key={item}
          variant='body2'
        >
          {item}
        </Typography>
      ))}
      {emails.length > 0 && (
        <Splitter
          variant='horizontal'
          size={1}
        />
      )}
      {emails.map(item => (
        <Typography
          key={item}
          component={Link}
          href={`mailto:${item}`}
          variant='body2'
          color='primary'
        >
          {item}
        </Typography>
      ))}
    </Wrapper>
  );
};
