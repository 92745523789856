import styled from '@emotion/styled';
import { ListItem, ListItemIcon, Typography } from '@mui/material';

export const HeaderListItem = styled(ListItem)`
  flex-direction: column;
  align-items: flex-start;
`;

export const LogoIconWrapper = styled(ListItemIcon)`
  margin-right: ${p => p.theme.spacing()};
  color: white;
`;

export const MPRoleName = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;

export const UserType = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
  line-height: 1.5rem;
`;

export const PrivilegeIcon = styled.img`
  width: 5rem;
  height: auto;
`;
