import {
  Address,
  EPartnerOwnershipType,
  EPartnerPermission,
  EPartnerStatus,
  EPartnerType,
  Nullable,
  PartnerCompanyDataDraft,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
  UUID,
} from '@/domain';
import { ButtonLink } from '@components/common/buttons/link';
import { EMPStepperTransitionMethod } from '@components/common/stepper';
import { DefaultContentWrapper } from '@components/common/wrappers/content';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { Fade, Typography } from '@mui/material';
import { MPTag } from '@ui-kit/tag';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Notifier from '../../../../system/notifier';
import MasterActionsComponent from '../../../components/common/actions/master';
import useNavAdapter from '../../../components/common/actions/navAdapter/hooks';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import useValidation from '../../../hooks/validation/useValidation';
import { PrivilegeRestoreIcon } from '../../../icons';
import { OnChangeObjectAttribute, PanelAction, PanelActions } from '../../../types';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import PartnerBySelfRegistrationSuccessDialog from '../../general/partner/components/bySelfRegistrationSuccessDialog';
import PartnerRegistrationSuccessDialog from '../../general/partner/components/registrationSuccessDialog';
import usePartnerDetailsValidation from '../../general/partner/hooks/usePartnerDetailsValidation';
import usePartnerDocumentsValidation from '../../general/partner/hooks/usePartnerDocumentsValidation';
import usePartnerGeneralValidation from '../../general/partner/hooks/usePartnerGeneralValidation';
import usePartnerStepper from '../../general/partner/hooks/useStepper';
import { EPartnerStep, EPartnerTableTab, PartnerStep } from '../../general/partner/types';
import { getPartnerStatusText, getPartnerStatusType } from '../../general/partner/utils/common';
import {
  getPartnerEditPermissionsStepValidation,
  PartnerDataStepPermissions,
  partnerEditAddressStepValidation,
} from '../../general/partner/validation';
import StepperContainer from '../../general/stepper/container';
import { getPartnerDetailsRoute, getPartnersTableRoute } from '../../partner/entry';
import { getPartnerManagementDetailsRoute } from '../../partner/management/entry';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { EPartnerApplicationActionType, PartnerApplicationEditActionType } from '../types';
import { partnerApplicationConsiderDays } from '../utils';
import PartnerApplicationEdit from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import PartnerApplicationEditNotifier from './notifier';
import {
  partnerApplicationEditAddressSelector,
  partnerApplicationEditApproveSelector,
  partnerApplicationEditDataModifiedSelector,
  partnerApplicationEditDataSelector,
  partnerApplicationEditExtraDataSelector,
  partnerApplicationEditFetchSelector,
  partnerApplicationEditPartnerTypeSelector,
  partnerApplicationEditPermissionsSelector,
  partnerApplicationEditSaveSelector,
  partnerApplicationEditSendToVerificationSelector,
} from './store/selectors';
import {
  partnerApplicationEditApprove,
  partnerApplicationEditFetch,
  partnerApplicationEditSave,
  partnerApplicationEditSendToVerification,
  partnerApplicationEditSetAddress,
  partnerApplicationEditSetAttribute,
  partnerApplicationEditSetCompanyAttribute,
  partnerApplicationEditSetIndividualAttribute,
  partnerApplicationEditSetOrgType,
  partnerApplicationEditSetPartnerType,
  partnerApplicationEditSetPermissions,
  partnerApplicationEditStateReset,
} from './store/slice';
import { getPartnerApplicationEditActions } from './utils';

interface PartnerApplicationEditContainerProps {
  readonly partnerId: UUID;
  readonly step: EPartnerStep;
  readonly isAdmin?: boolean;
  readonly canApprove?: boolean;
  readonly canSendToVerification?: boolean;
  readonly canReturnToVerification?: boolean;
  readonly canChangePermissions?: boolean;
  readonly refreshCurrentUser: () => void;
}

const PartnerApplicationEditContainer = (props: PartnerApplicationEditContainerProps) => {
  const {
    partnerId,
    step,
    isAdmin = false,
    canApprove,
    canSendToVerification,
    canReturnToVerification,
    canChangePermissions,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { logOut } = useCurrentUser();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const [validateOnChangeGeneral, setValidateOnChangeGeneral] = useState<boolean>(false);
  const [validateOnChangeDetails, setValidateOnChangeDetails] = useState<boolean>(false);
  const [validateOnChangeAddress, setValidateOnChangeAddress] = useState<boolean>(false);
  const [validateOnChangeDocuments, setValidateOnChangeDocuments] = useState<boolean>(false);
  const [validateOnChangePermissions, setValidateOnChangePermissions] = useState<boolean>(false);

  const [notifierVisible, setNotifierVisible] = useState<boolean>(false);

  const { partnerOwnerShipTypes: ownerShipTypes, taxationSystemTypes, partnerTypes } = useSelector(nsiDataSelector);
  const partnerApplication = useSelector(partnerApplicationEditDataSelector);
  const permissions = useSelector(partnerApplicationEditPermissionsSelector);
  const partnerType = useSelector(partnerApplicationEditPartnerTypeSelector);
  const modified = useSelector(partnerApplicationEditDataModifiedSelector);
  const extraData = useSelector(partnerApplicationEditExtraDataSelector);
  const address = useSelector(partnerApplicationEditAddressSelector);

  const { isFetching } = useSelector(partnerApplicationEditFetchSelector);
  const { isFetching: isSaving } = useSelector(partnerApplicationEditSaveSelector);
  const { isFetching: isSending } = useSelector(partnerApplicationEditSendToVerificationSelector);
  const { data: approvedPartner, isFetching: isApproving } = useSelector(partnerApplicationEditApproveSelector);

  const partnerStatus = extraData?.status ?? EPartnerStatus.Unverified;
  const statusType = getPartnerStatusType(partnerStatus);

  const orgType = partnerApplication?.regInfo?.orgType;
  const bySelfRegistration = partnerApplication?.owner && partnerApplication.owner.id === partnerApplication?.createdBy;

  const { currentStep, steps, nextStep, isLastStep, openStep, openPrevStep, currentStepIndex } = usePartnerStepper({
    currentStepKey: step,
    orgType,
    canChangePermissions,
  });

  const actions: PanelActions<PartnerApplicationEditActionType> = isLastStep
    ? getPartnerApplicationEditActions({
        partnerStatus,
        canApprove,
        canSendToVerification,
        canReturnToVerification,
      })
    : [];

  const {
    validationResultCompany: validationGeneralCompany,
    validationResultIndividual: validationGeneralIndividual,
    isValid: isValidGeneral,
    validate: validateGeneral,
  } = usePartnerGeneralValidation({
    object: partnerApplication,
    validateOnChange: validateOnChangeGeneral,
  });

  const {
    validationResultCompany: validationDetailsCompany,
    validationResultIndividual: validationDetailsIndividual,
    isValid: isValidDetails,
    validate: validateDetails,
  } = usePartnerDetailsValidation({
    object: partnerApplication,
    validateOnChange: validateOnChangeDetails,
  });

  const {
    validationResult: validationResultAddress,
    isValid: isValidAddress,
    validate: validateAddress,
  } = useValidation<Address | {}>({
    object: partnerApplication?.address ?? {},
    validateOnChange: validateOnChangeAddress,
    rules: partnerEditAddressStepValidation,
  });

  const {
    validationResultCompany: validationDocumentsCompany,
    validationResultIndividual: validationDocumentsIndividual,
    isValid: isValidDocuments,
    validate: validateDocuments,
  } = usePartnerDocumentsValidation({
    object: partnerApplication,
    validateOnChange: validateOnChangeDocuments,
  });

  const {
    validationResult: validationResultPermissions,
    isValid: isValidPermissions,
    validate: validatePermissions,
  } = useValidation<PartnerDataStepPermissions>({
    object: partnerApplication ? { ...partnerApplication, type: partnerType, permissions } : null,
    validateOnChange: validateOnChangePermissions,
    rules: getPartnerEditPermissionsStepValidation({ canChangePermissions }),
  });

  const onRestore = null;

  const onSendToVerification = () => {
    if (partnerApplication) {
      const isValidObject = validateDocuments();
      if (!isValidObject) {
        setValidateOnChangeDocuments(true);
        return;
      }
      dispatch(partnerApplicationEditSendToVerification({ partnerId, partnerApplication }))
        .unwrap()
        .then(() => {
          setNotifierVisible(true);
        });
    }
  };

  const onSaveAndClose = () => {
    if (partnerApplication) {
      const isValidObject = validateDocuments();
      if (!isValidObject) {
        setValidateOnChangeDocuments(true);
        return;
      }
      dispatch(
        partnerApplicationEditSave({
          partnerId,
          partnerApplication,
        })
      )
        .unwrap()
        .then(() => onClose());
    }
  };

  const onApprove = () => {
    if (partnerApplication) {
      const isValidObject = validatePermissions();

      if (!isValidObject) {
        setValidateOnChangePermissions(true);
        return;
      }
      dispatch(
        partnerApplicationEditApprove({
          partnerId,
          partnerApplication,
          permissions,
          type: partnerType!,
        })
      );
    }
  };

  const onClose = () => {
    if (isAdmin) {
      gotoPrevIndependentLocation(getPartnersTableRoute({ tab: EPartnerTableTab.Unverified }));
    } else if (extraData?.status === EPartnerStatus.Unverified) {
      if (partnerApplication && modified) {
        dispatch(
          partnerApplicationEditSave({
            partnerId,
            partnerApplication,
          })
        )
          .unwrap()
          .finally(() => logOut());
      } else {
        logOut();
      }
    } else {
      gotoPrevIndependentLocation('/');
    }
  };

  const onChangeOrgType = (value: EPartnerOwnershipType) => {
    dispatch(partnerApplicationEditSetOrgType(value));
  };

  const onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft> = (name, value) => {
    dispatch(partnerApplicationEditSetAttribute({ name, value }));
  };

  const onChangePermissions = (newPermissions: Nullable<EPartnerPermission[]>) => {
    dispatch(partnerApplicationEditSetPermissions(newPermissions));
  };

  const onChangeType = (newType: Nullable<EPartnerType>) => {
    dispatch(partnerApplicationEditSetPartnerType(newType));
  };

  const onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft> = (name, value) => {
    dispatch(partnerApplicationEditSetCompanyAttribute({ name, value }));
  };

  const onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft> = (name, value) => {
    dispatch(partnerApplicationEditSetIndividualAttribute({ name, value }));
  };

  const onChangeAddress = (newAddress: Address) => {
    dispatch(partnerApplicationEditSetAddress(newAddress));
  };

  const onPanelAction = (action: PanelAction<PartnerApplicationEditActionType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerApplicationActionType.Approve:
        onApprove();
        break;
      case EPartnerApplicationActionType.Save:
        onSaveAndClose();
        break;
      case EPartnerApplicationActionType.SendToVerification:
      case EPartnerApplicationActionType.ReturnToVerification:
        onSendToVerification();
        break;
    }
  };

  const onChangeStep = (nextStep: PartnerStep) => {
    if (partnerApplication && step !== nextStep.key) {
      switch (step) {
        case EPartnerStep.General: {
          const isValidObject = validateGeneral();
          if (!isValidObject) {
            setValidateOnChangeGeneral(true);
            return;
          }
          break;
        }
        case EPartnerStep.Details: {
          const isValidObject = validateDetails();
          if (!isValidObject) {
            setValidateOnChangeDetails(true);
            return;
          }
          break;
        }
        case EPartnerStep.Address: {
          const isValidObject = validateAddress();

          if (!isValidObject) {
            setValidateOnChangeAddress(true);
            return;
          }
          break;
        }
        case EPartnerStep.Documents: {
          const isValidObject = validateDocuments();
          if (!isValidObject) {
            setValidateOnChangeDocuments(true);
            return;
          }
          break;
        }
        case EPartnerStep.Permissions: {
          break;
        }
      }
      dispatch(
        partnerApplicationEditSave({
          partnerId,
          partnerApplication,
        })
      )
        .unwrap()
        .then(() => openStep(nextStep));
    }
  };

  const onNextStep = () => {
    onChangeStep(nextStep);
  };

  const onNotificationRead = () => {
    if (isAdmin) {
      gotoPrevIndependentLocation(getPartnerManagementDetailsRoute({ id: partnerId }));
    } else {
      const hrefParts = window.location.href.split(history.location.pathname);
      window.location.href = `${hrefParts[0]}${getPartnerDetailsRoute({ id: partnerId })}`;
    }
  };

  useEffect(() => {
    if (approvedPartner?.status && approvedPartner?.status !== EPartnerStatus.Enabled) {
      Notifier.getInstance().addErrorNotice('Не удалось активировать партнёра');
    }
  }, [approvedPartner?.status]);

  useEffect(() => {
    const promise = dispatch(partnerApplicationEditFetch({ partnerId }));

    return () => {
      promise?.abort();
      dispatch(partnerApplicationEditStateReset());
    };
  }, [dispatch]);

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep: orgType && onNextStep,
    stepsCount: steps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<PartnerApplicationEditActionType>
      show={!!actions.length || !!navActions.length}
      actions={actions}
      navAdapter={navAdapter}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {partnerApplication && (
          <>
            <StepperContainer<EPartnerStep>
              step={currentStep}
              steps={steps}
              forwardTransition={EMPStepperTransitionMethod.Neighbour}
              onClick={orgType && onChangeStep}
            />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                {notifierVisible && (
                  <PartnerApplicationEditNotifier
                    considerDuration={partnerApplicationConsiderDays}
                    onRead={onNotificationRead}
                  />
                )}
                <DefaultHeader
                  sticky
                  headline={
                    statusType && (
                      <MPTag
                        bold
                        label={getPartnerStatusText(partnerStatus)}
                        color={statusType}
                      />
                    )
                  }
                  actions={
                    partnerStatus !== EPartnerStatus.Enabled &&
                    onRestore && (
                      <ButtonLink onClick={onRestore}>
                        <PrivilegeRestoreIcon />
                        <Typography>Отменить все изменения</Typography>
                      </ButtonLink>
                    )
                  }
                  onClose={onClose}
                >
                  <TitleWrapper>
                    <Typography variant='h2'>{isAdmin ? 'Добавление партнера' : 'Заявка на партнёрство'}</Typography>
                  </TitleWrapper>
                </DefaultHeader>
                <PartnerApplicationEdit
                  partnerApplication={partnerApplication}
                  partnerType={partnerType}
                  partnerTypes={partnerTypes}
                  permissions={permissions}
                  address={address}
                  step={step}
                  isAdmin={isAdmin}
                  ownerShipTypes={ownerShipTypes}
                  taxationSystemTypes={taxationSystemTypes}
                  isValidGeneral={isValidGeneral}
                  isValidDetails={isValidDetails}
                  isValidAddress={isValidAddress}
                  isValidDocuments={isValidDocuments}
                  isValidPermissions={isValidPermissions}
                  validationGeneralCompany={validationGeneralCompany}
                  validationGeneralIndividual={validationGeneralIndividual}
                  validationDetailsCompany={validationDetailsCompany}
                  validationDetailsIndividual={validationDetailsIndividual}
                  validationAddress={validationResultAddress}
                  validationDocumentsCompany={validationDocumentsCompany}
                  validationDocumentsIndividual={validationDocumentsIndividual}
                  validationPermissions={validationResultPermissions}
                  onChangeOrgType={onChangeOrgType}
                  onChangeAttribute={onChangeAttribute}
                  onChangeCompanyAttribute={onChangeCompanyAttribute}
                  onChangeIndividualCompanyAttribute={onChangeIndividualAttribute}
                  onChangeAddress={onChangeAddress}
                  onChangePermissions={onChangePermissions}
                  onChangeType={onChangeType}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {!bySelfRegistration && (
          <PartnerRegistrationSuccessDialog
            partner={approvedPartner}
            onAction={onClose}
          />
        )}
        {bySelfRegistration && (
          <PartnerBySelfRegistrationSuccessDialog
            partner={approvedPartner}
            onAction={onClose}
          />
        )}
        {(isFetching || isSaving || isSending || isApproving) && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerApplicationEditContainer;
