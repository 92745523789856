import EntityMasterInfoPanelItem from '@components/common/entityMaster/infoPanel/item';
import { SidePanelContentWrapper } from '@components/common/wrappers/sidePanel';
import usePartnerInfoPanels from '@features/general/partner/hooks/useInfoPanels';
import { SupportMPInfoPanelContainer } from '@features/support/infoPanel';
import { useSelector } from 'react-redux';
import { partnerApplicationEditDataSelector } from '../store/selectors';

interface PartnerApplicationEditSidePanelsAdapterProps {
  readonly isAdmin: boolean;
}

export const PartnerApplicationEditSidePanelsAdapter = (props: PartnerApplicationEditSidePanelsAdapterProps) => {
  const { isAdmin } = props;
  const partner = useSelector(partnerApplicationEditDataSelector);

  const infoPanels = usePartnerInfoPanels({
    partner,
  });

  return (
    <SidePanelContentWrapper>
      {infoPanels?.map((infoPanel, index) => (
        <EntityMasterInfoPanelItem
          key={index}
          infoPanel={infoPanel}
        />
      ))}
      {!isAdmin && <SupportMPInfoPanelContainer />}
    </SidePanelContentWrapper>
  );
};
