import TableScrollbarOverlay from '@components/common/table/scrollbarOverlay';
import { FormItem } from '@components/forms/item';
import { TableWrapper } from '@layouts/tableCommon/controls';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCost } from '../../../../utils';
import BookingOrderItemsTable from '../../components/itemsTable';
import {
  bookingOrderDetailsBookingOrderCostSelector,
  bookingOrderDetailsBookingOrderItemsSelector,
  bookingOrderDetailsBookingOrderObjectNameSelector,
} from '../store/selectors';

export const BookingOrderDetailsItemsTableAdapter = () => {
  const [scrollRef, setScrollRef] = useState<HTMLElement | undefined>();

  const items = useSelector(bookingOrderDetailsBookingOrderItemsSelector);
  const objectName = useSelector(bookingOrderDetailsBookingOrderObjectNameSelector);
  const cost = useSelector(bookingOrderDetailsBookingOrderCostSelector);

  return (
    <FormItem title={`Состав заказа по объекту «${objectName}»`}>
      <TableScrollbarOverlay bottomAnchor={scrollRef}>
        <TableWrapper>
          <BookingOrderItemsTable items={items} />
        </TableWrapper>
      </TableScrollbarOverlay>
      <Typography
        variant='h3'
        ref={(ref: any) => setScrollRef(ref)}
      >
        Итого: {formatCost(cost)}
      </Typography>
    </FormItem>
  );
};
