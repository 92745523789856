import { TradeOffer } from '@/domain';
import { useTradeOfferActions } from '@features/tradeOffer/actions/useActions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EPanelActionPosition } from '../../../../types';
import { TradeOfferLifeCycle } from '../../../general/offer/lifecycle/types';
import { EOfferActionType, OfferActions, OfferActionTableType } from '../../../general/offer/types';
import { getOfferTableActions } from '../../../general/offer/utils/actions';
import { ETradeOfferTableFilterItem } from '../../filterUtils';
import { ETradeOfferTableColumn } from '../../types';
import TradeOfferTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  tradeOffersFilterSelector,
  tradeOffersGuidSelector,
  tradeOffersSortColumnSelector,
  tradeOffersSortDirectionSelector,
  tradeOffersTradeOffersSelector,
} from '../store/selectors';
import { TradeOfferActionCall, TradeOfferActionCommonType } from '../utils';

interface TradeOffersTableAdapterProps {
  readonly lifecycle: TradeOfferLifeCycle;
  readonly filterAdapter: DataFilterAdapter<ETradeOfferTableColumn, ETradeOfferTableFilterItem>;
  readonly tabActions: OfferActions<OfferActionTableType>;
}

const TradeOffersTableAdapter = (props: TradeOffersTableAdapterProps) => {
  // TODO NOTE (@Protopopov Ruslan): tabActions урезаны по userAccessMatrix (canEdit etc)
  const { lifecycle, filterAdapter, tabActions } = props;

  const handlers = useContextHandlers();
  const { onTradeOfferClick, onTradeOfferOpenEdit } = useContextConfig();

  const guid = useSelector(tradeOffersGuidSelector);
  const tradeOffers = useSelector(tradeOffersTradeOffersSelector);
  const sortColumn = useSelector(tradeOffersSortColumnSelector);
  const sortDirection = useSelector(tradeOffersSortDirectionSelector);
  const filter = useSelector(tradeOffersFilterSelector);

  const {
    utils: {
      selectors: { isTradeOffersAnyChangedSelector },
    },
  } = useTradeOfferActions();

  const needRefresh = useSelector(isTradeOffersAnyChangedSelector);

  const onActionClick = (call: TradeOfferActionCall) => {
    const { action, tradeOffer } = call;
    switch (action) {
      case EOfferActionType.Pause:
        handlers.onTradeOfferTryPause(tradeOffer);
        break;
      case EOfferActionType.Reject:
        handlers.onTradeOfferTryReject(tradeOffer);
        break;
      case EOfferActionType.Archive:
        handlers.onTradeOfferTryArchive(tradeOffer);
        break;
      case EOfferActionType.Delete:
        handlers.onTradeOfferTryDelete(tradeOffer);
        break;
      case EOfferActionType.Resume:
        handlers.onTradeOfferResume(tradeOffer);
        break;
      case EOfferActionType.Approve:
        handlers.onTradeOfferTryApprove(tradeOffer);
        break;
      case EOfferActionType.Duplicate:
        handlers.onTradeOfferDuplicate(tradeOffer).then(duplicate => {
          if (duplicate) {
            onTradeOfferOpenEdit(duplicate);
          }
        });
        break;
      case EOfferActionType.Retrieve:
        handlers.onTradeOfferTryRetrieve(tradeOffer);
        break;
      case EOfferActionType.ChangeModerator:
        handlers.onTradeOfferTryChangeModerator(tradeOffer);
        break;
      case EOfferActionType.ReturnToVerification: {
        handlers.onTradeOfferReturnToVerification(tradeOffer);
      }
    }
  };

  const getTradeOfferCommonActions = (
    tradeOffer: TradeOffer,
    actions: OfferActions<OfferActionTableType>
  ): OfferActions<TradeOfferActionCommonType> => {
    return getOfferTableActions(lifecycle, tradeOffer, actions).filter(action =>
      action.position.includes(EPanelActionPosition.Default)
    ) as OfferActions<TradeOfferActionCommonType>;
  };

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <TradeOfferTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      tradeOffers={tradeOffers}
      getCommonActions={tradeOffer => getTradeOfferCommonActions(tradeOffer, tabActions)}
      onRequestSort={handlers.onChangeSort}
      onTradeOfferClick={onTradeOfferClick}
      onTradeOfferAction={onActionClick}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default TradeOffersTableAdapter;
