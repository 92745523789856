import styled from '@emotion/styled';

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};
`;

export const StepperButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
  align-items: flex-start;
`;

export const FilledContainer = styled.div`
  flex: 1;
`;

export const ContentWrapper = styled.div`
  width: 24rem;
`;
