import { EPartnerStatus, Partner, PartnerDraft } from '@/domain';
import { EntityInfoPanels } from '../../../../types';
import { EPartnerInfoPanel } from '../enums';
import { PartnerInfoPanelType } from '../types';

type UsePartnerInfoPanels = Nullable<EntityInfoPanels<PartnerInfoPanelType>>;

interface UsePartnerInfoPanelsProps {
  readonly partner: Nullable<Partner | PartnerDraft>;
}

const usePartnerInfoPanels = (props: UsePartnerInfoPanelsProps): UsePartnerInfoPanels => {
  const { partner } = props;

  const infoPanels: EntityInfoPanels<PartnerInfoPanelType> = [];

  if (partner?.status === EPartnerStatus.Rejected) {
    const text: string[] = [];

    if (partner?.rejectionReasonType?.name) {
      text.push(partner.rejectionReasonType.name);
    }
    if (partner?.lastStatusComment) {
      text.push(partner?.lastStatusComment ?? null);
    }

    infoPanels.push({
      type: EPartnerInfoPanel.Rejected,
      title: 'Причина отклонения',
      text: text.length ? text : null,
    });
  }

  return infoPanels.length ? infoPanels : null;
};

export default usePartnerInfoPanels;
