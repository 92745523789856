import {
  Address,
  EPartnerOwnershipType,
  EPartnerPermission,
  EPartnerType,
  ETaxationSystemType,
  Nullable,
  PartnerCompanyDataDraft,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
  SportOptionTyped,
} from '@/domain';
import { SideColumn } from '@layouts/masterContentGrid/controls';
import { OnChangeObjectAttribute } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import { EPartnerStep } from '../../general/partner/types';
import {
  PartnerDataStepPermissions,
  PartnerDetailsCompany,
  PartnerDetailsIndividual,
  PartnerDocumentsCompany,
  PartnerDocumentsIndividual,
  PartnerGeneralCompany,
  PartnerGeneralIndividual,
} from '../../general/partner/validation';
import PartnerApplicationEditStepAddress from '../components/steps/address/edit';
import PartnerApplicationEditStepDetails from '../components/steps/details/edit';
import PartnerApplicationEditStepDocuments from '../components/steps/documents/edit';
import PartnerApplicationEditStepGeneral from '../components/steps/general/edit';
import PartnerApplicationEditStepPermissions from '../components/steps/permissions/edit';
import { PartnerApplicationEditSidePanelsAdapter } from './adapters/sidePanels';
import { ColumnWrapper, Content, StepWrapper, Wrapper } from './controls';

interface PartnerApplicationEditProps {
  readonly partnerApplication: PartnerDataDraft;
  readonly partnerType: Nullable<EPartnerType>;
  readonly permissions: Nullable<EPartnerPermission[]>;
  readonly address: Nullable<Address>;
  readonly isAdmin: boolean;
  readonly step: EPartnerStep;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly isValidGeneral: boolean;
  readonly isValidDetails: boolean;
  readonly isValidAddress: boolean;
  readonly isValidDocuments: boolean;
  readonly isValidPermissions: boolean;
  readonly validationGeneralCompany: Nullable<ValidationResult<PartnerGeneralCompany>>;
  readonly validationGeneralIndividual: Nullable<ValidationResult<PartnerGeneralIndividual>>;
  readonly validationDetailsCompany: Nullable<ValidationResult<PartnerDetailsCompany>>;
  readonly validationDetailsIndividual: Nullable<ValidationResult<PartnerDetailsIndividual>>;
  readonly validationAddress: Nullable<ValidationResult<Address>>;
  readonly validationDocumentsCompany: Nullable<ValidationResult<PartnerDocumentsCompany>>;
  readonly validationDocumentsIndividual: Nullable<ValidationResult<PartnerDocumentsIndividual>>;
  readonly validationPermissions: Nullable<ValidationResult<PartnerDataStepPermissions>>;
  readonly onChangeOrgType: (value: EPartnerOwnershipType) => void;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
  readonly onChangeIndividualCompanyAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
  readonly onChangeAddress: (address: Address) => void;
  readonly onChangePermissions: (permissions: EPartnerPermission[]) => void;
  readonly onChangeType: (newType: Nullable<EPartnerType>) => void;
}

const PartnerApplicationEdit = (props: PartnerApplicationEditProps) => {
  const {
    partnerApplication,
    partnerType,
    permissions,
    address,
    step,
    isAdmin,
    ownerShipTypes,
    taxationSystemTypes,
    partnerTypes,
    isValidGeneral,
    isValidDetails,
    isValidAddress,
    isValidDocuments,
    isValidPermissions,
    validationGeneralCompany,
    validationGeneralIndividual,
    validationDetailsCompany,
    validationDetailsIndividual,
    validationAddress,
    validationDocumentsCompany,
    validationDocumentsIndividual,
    validationPermissions,
    onChangeOrgType,
    onChangeAttribute,
    onChangeCompanyAttribute,
    onChangeIndividualCompanyAttribute,
    onChangeAddress,
    onChangePermissions,
    onChangeType,
  } = props;

  return (
    <Wrapper>
      <StepWrapper>
        <ColumnWrapper>
          <Content>
            {step === EPartnerStep.General && (
              <PartnerApplicationEditStepGeneral
                partnerApplication={partnerApplication}
                validationCompany={validationGeneralCompany}
                validationIndividual={validationGeneralIndividual}
                isValid={isValidGeneral}
                onChangeOrgType={onChangeOrgType}
                onChangeAttribute={onChangeAttribute}
                onChangeCompanyAttribute={onChangeCompanyAttribute}
                ownerShipTypes={ownerShipTypes}
              />
            )}
            {step === EPartnerStep.Address && (
              <PartnerApplicationEditStepAddress
                address={address}
                orgType={partnerApplication.regInfo?.orgType ?? null}
                isValid={isValidAddress}
                validation={validationAddress}
                onChangeAddress={onChangeAddress}
              />
            )}
            {step === EPartnerStep.Details && (
              <PartnerApplicationEditStepDetails
                partnerApplication={partnerApplication}
                taxationSystemTypes={taxationSystemTypes}
                isValid={isValidDetails}
                validationCompany={validationDetailsCompany}
                validationIndividual={validationDetailsIndividual}
                onChangeAttribute={onChangeAttribute}
                onChangeCompanyAttribute={onChangeCompanyAttribute}
                onChangeIndividualAttribute={onChangeIndividualCompanyAttribute}
              />
            )}
            {step === EPartnerStep.Documents && (
              <PartnerApplicationEditStepDocuments
                partnerApplication={partnerApplication}
                isValid={isValidDocuments}
                validationCompany={validationDocumentsCompany}
                validationIndividual={validationDocumentsIndividual}
                onChangeAttribute={onChangeAttribute}
                onChangeCompanyAttribute={onChangeCompanyAttribute}
                onChangeIndividualAttribute={onChangeIndividualCompanyAttribute}
              />
            )}
            {step === EPartnerStep.Permissions && (
              <PartnerApplicationEditStepPermissions
                agreements={partnerApplication.agreements}
                isValid={isValidPermissions}
                partnerType={partnerType}
                partnerTypes={partnerTypes}
                permissions={permissions}
                validation={validationPermissions}
                onChangeAgreements={newAgreements => onChangeAttribute('agreements', newAgreements)}
                onChangePermissions={onChangePermissions}
                onChangeType={onChangeType}
              />
            )}
          </Content>
        </ColumnWrapper>

        <SideColumn sideContentPosition={'fixed'}>
          <PartnerApplicationEditSidePanelsAdapter isAdmin={isAdmin} />
        </SideColumn>
      </StepWrapper>
    </Wrapper>
  );
};

export default PartnerApplicationEdit;
