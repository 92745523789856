import { ContentWrapper } from '@features/bookingOrder/edit/adapters/controls';
import { useSelector } from 'react-redux';
import BookingOrderDetailsInfo from '../../components/detailsInfo';
import { bookingOrderEditOrderSelector } from '../store/selectors';
import FormItemAdapter from './formItem';

export const BookingOrderEditDetailsInfoAdapter = () => {
  const bookingOrder = useSelector(bookingOrderEditOrderSelector);

  if (!bookingOrder) {
    return null;
  }

  return (
    <FormItemAdapter
      gridSize={12}
      title='Сведения о клиенте'
    >
      <ContentWrapper>
        <BookingOrderDetailsInfo customer={bookingOrder.customer} />
      </ContentWrapper>
    </FormItemAdapter>
  );
};
