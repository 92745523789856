import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${p => p.theme.spacing(4)};
  background: ${p => p.theme.palette.error.light};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 2}px;

  gap: ${p => p.theme.spacing(0.5)};
`;
