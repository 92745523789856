import { EPartnerStatus, Partner } from '@/domain';
import { MenuPanelCommonHeader } from '@components/common/menuPanel/header';
import Splitter from '@components/common/splitter';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { ListItemText } from '@mui/material';
import { ETagColors, MPTag } from '@ui-kit/tag';
import { useMemo } from 'react';
import { HeaderItem, LogoIconWrapper, UserType } from '../controls';

interface PartnerManagementMenuHeaderAdapterProps {
  readonly partner: Partner;
  readonly goBack: () => void;
}

export const PartnerManagementMenuHeaderAdapter = (props: PartnerManagementMenuHeaderAdapterProps) => {
  const { partner, goBack } = props;

  return useMemo(() => {
    return (
      <MenuPanelCommonHeader>
        <HeaderItem onClick={goBack}>
          <LogoIconWrapper>
            <ArrowBackIosOutlinedIcon fontSize='small' />
          </LogoIconWrapper>
          <ListItemText
            primary={<UserType variant='subtitle2'>{partner.name}</UserType>}
            secondary={
              partner?.status === EPartnerStatus.Rejected ? (
                <>
                  <Splitter size={1} />
                  <MPTag
                    dense
                    variant='filled'
                    color={ETagColors.Warning}
                    label='Заявка на партнерство отклонена'
                  />
                </>
              ) : null
            }
          />
        </HeaderItem>
      </MenuPanelCommonHeader>
    );
  }, [partner, goBack]);
};
