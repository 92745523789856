import { Grid, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import MenuButton from '../../../../components/common/buttons/menu';
import FileDownloadLink from '../../../../components/common/files/download/link';
import DefaultHeader from '../../../../components/common/header';
import { MPButton } from '../../../../theme/ui-kit/button';
import { offerExportAccept } from '../../../general/offer/constants';
import { EOfferTableTab } from '../../../general/offer/types';
import { getStatesFilterForOfferTableTab } from '../../../general/offer/utils/table';
import { Tooltip, TooltipTitleWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOffersFilterSelector, bookingOffersSortSelector } from '../store/selectors';
import { getBookingOffersDownloadUrl } from '../utils';

interface BookingOffersTableHeaderAdapterProps {
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly createInfo?: Nullable<string>;
  readonly isCreateDisabled?: boolean;
  readonly canCreate?: boolean;
  readonly canDownload?: boolean;
}

const getStatusesByTab = (t: EOfferTableTab) => {
  return getStatesFilterForOfferTableTab(t, [EOfferStatus.OnModeration, EOfferStatus.WaitingForModeration]);
};

const BookingOffersTableHeaderAdapter = (props: BookingOffersTableHeaderAdapterProps) => {
  const { createInfo, isCreateDisabled, canCreate, partnerId, tab, canDownload } = props;

  const { onCreate } = useContextHandlers();

  const sort = useSelector(bookingOffersSortSelector);
  const filter = useSelector(bookingOffersFilterSelector);
  const currentStatuses = getStatusesByTab(tab);

  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);

  const showCreateButton = canCreate;
  const showMenu = canDownload;

  const downloadUrl = getBookingOffersDownloadUrl({
    sort,
    skipPageable: true,
    states: currentStatuses,
    id: null,
    partnerId,
    filter,
  });

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        (showCreateButton || showMenu) && (
          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            {showMenu && (
              <Grid item>
                <MenuButton
                  variant='outlined'
                  color='primary'
                  id={uuidv4()}
                  closeMarker={menuCloseMarker}
                >
                  {canDownload && (
                    <MenuItem>
                      <FileDownloadLink
                        src={downloadUrl}
                        accept={offerExportAccept}
                        fileName={'объекты_досуга_и_отдыха'}
                        component={'div'}
                        onDownload={() => setMenuCloseMarker(Symbol())}
                      >
                        Выгрузить объекты досуга и отдыха
                      </FileDownloadLink>
                    </MenuItem>
                  )}
                </MenuButton>
              </Grid>
            )}
            {showCreateButton && (
              <Grid item>
                <Tooltip
                  arrow
                  placement='bottom-end'
                  open={!!createInfo}
                  title={
                    <TooltipTitleWrapper>
                      <Typography>{createInfo}</Typography>
                    </TooltipTitleWrapper>
                  }
                >
                  <MPButton
                    disabled={isCreateDisabled}
                    variant='outlined'
                    size='small'
                    onClick={onCreate}
                  >
                    Создать объект
                  </MPButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )
      }
    >
      <Typography variant='h2'>Досуг и отдых</Typography>
    </DefaultHeader>
  );
};

export default BookingOffersTableHeaderAdapter;
