import { PartnerDraft } from '@/domain';
import { Grid, Typography } from '@mui/material';
import { AddressHelper } from '../../../../../utils/address';

interface PartnerDetailsInfoAddressProps {
  readonly partner: PartnerDraft;
}

const PartnerDetailsInfoAddress = ({ partner }: PartnerDetailsInfoAddressProps) => {
  const { address } = partner;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Область / Республика / Край
        </Typography>
        <Typography>{address ? new AddressHelper(address).getLocalityRegion() : '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Адрес
        </Typography>
        <Typography>{address ? new AddressHelper(address).getFullPathWithPostalCode() : '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoAddress;
