import { ESortDirection, TradeOffer } from '@/domain';
import TableActionsContainer from '@components/common/table/actions/container';
import { DataTableLoader } from '@components/common/table/loader';
import { useTradeOfferActionsSelectors } from '@features/tradeOffer/actions/useActions';
import TradeOfferTableCellAggregatorIntegrationId from '@features/tradeOffer/components/tableCell/aggregatorIntegrationId';
import TradeOfferTableCellPrice from '@features/tradeOffer/components/tableCell/price';
import TradeOfferTableCellTargetClientOrgs from '@features/tradeOffer/components/tableCell/targetClientOrgs';
import TradeOfferTableCellTargetExternalUsers from '@features/tradeOffer/components/tableCell/targetExternalUsers';
import { useCallback } from 'react';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import { OfferActions } from '../../general/offer/types';
import TradeOfferTableCellApprovingAdmin from '../components/tableCell/approvingAdmin';
import TradeOfferTableCellCode from '../components/tableCell/code';
import TradeOfferTableCellLastStatusAuthor from '../components/tableCell/lastStatusAuthor';
import TradeOfferTableCellLastStatusDate from '../components/tableCell/lastStatusDate';
import TradeOfferTableCellName from '../components/tableCell/name';
import TradeOfferTableCellOfferCounts from '../components/tableCell/offerCounts';
import TradeOfferTableCellPartner from '../components/tableCell/partner';
import TradeOfferTableCellPausedReason from '../components/tableCell/pausedReason';
import TradeOfferTableCellPeriod from '../components/tableCell/period';
import TradeOfferTableCellPromotionType from '../components/tableCell/promotionType';
import TradeOfferTableCellStatus from '../components/tableCell/status';
import TradeOfferTableCellTargetLocalities from '../components/tableCell/targetLocalities';
import { ETradeOfferTableColumn } from '../types';
import TradeOffersTableCellAdapter from './adapters/cell';
import TradeOffersTableCellPromotionTypeAdapter from './adapters/cellPromotionType';
import TradeOffersTableCellStatusAdapter from './adapters/cellStatus';
import { tradeOffersIsFetchingSelector } from './store/selectors';
import { TradeOfferActionCall, TradeOfferActionCommonType } from './utils';

interface TradeOfferTableProps {
  readonly metadata: DataTableMetadata<ETradeOfferTableColumn>;
  readonly tradeOffers: TradeOffer[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly getCommonActions: (tradeOffer: TradeOffer) => OfferActions<TradeOfferActionCommonType>;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onTradeOfferClick: (tradeOffer: TradeOffer) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ETradeOfferTableColumn>) => void;
  readonly onTradeOfferAction: (call: TradeOfferActionCall) => void;
}

const TradeOfferTable = (props: TradeOfferTableProps) => {
  const {
    metadata,
    tradeOffers,
    sort,
    onRequestSort,
    onTradeOfferClick,
    getCommonActions,
    onTradeOfferAction,
    onChangeMetadata,
  } = props;

  const { getIsOfferProcessingSelector } = useTradeOfferActionsSelectors();

  const rows: DataTableRow<TradeOffer, ETradeOfferTableColumn>[] = tradeOffers?.map((tradeOffer, index) => {
    return {
      [ETradeOfferTableColumn.Code]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellCode}
        />
      ),
      [ETradeOfferTableColumn.LastStatusDate]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellLastStatusDate}
        />
      ),
      [ETradeOfferTableColumn.Name]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellName}
        />
      ),
      [ETradeOfferTableColumn.Partner]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPartner}
        />
      ),
      [ETradeOfferTableColumn.Price]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPrice}
        />
      ),
      [ETradeOfferTableColumn.PromotionType]: (
        <TradeOffersTableCellPromotionTypeAdapter
          index={index}
          component={TradeOfferTableCellPromotionType}
        />
      ),
      [ETradeOfferTableColumn.Period]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPeriod}
        />
      ),
      [ETradeOfferTableColumn.Status]: (
        <TradeOffersTableCellStatusAdapter
          index={index}
          component={TradeOfferTableCellStatus}
        />
      ),
      [ETradeOfferTableColumn.ApprovingAdmin]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellApprovingAdmin}
        />
      ),
      [ETradeOfferTableColumn.LastStatusAuthor]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellLastStatusAuthor}
        />
      ),
      [ETradeOfferTableColumn.OfferCounts]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellOfferCounts}
        />
      ),
      [ETradeOfferTableColumn.PausedReason]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPausedReason}
        />
      ),
      [ETradeOfferTableColumn.TargetLocalities]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellTargetLocalities}
        />
      ),
      [ETradeOfferTableColumn.TargetClientOrgs]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellTargetClientOrgs}
        />
      ),
      [ETradeOfferTableColumn.TargetExternalUsers]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellTargetExternalUsers}
        />
      ),
      [ETradeOfferTableColumn.AggregatorIntegrationId]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellAggregatorIntegrationId}
        />
      ),
      data: tradeOffer,
    };
  });

  const onTableCommonAction = useCallback(
    (action: TradeOfferActionCommonType, tradeOffer: TradeOffer) => onTradeOfferAction({ action, tradeOffer }),
    [onTradeOfferAction]
  );

  return (
    <DataTable<TradeOffer, ETradeOfferTableColumn>
      metadata={metadata}
      width='auto'
      overflowX='inherit'
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={tradeOffersIsFetchingSelector} />}
      rowActions
      hoverModule
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      onRowClick={(event, cell, { data }) => onTradeOfferClick(data)}
      onChangeMetadata={onChangeMetadata}
      getRowActions={tradeOffer => (
        <TableActionsContainer<TradeOffer, TradeOfferActionCommonType>
          entityLink={tradeOffer}
          actions={getCommonActions(tradeOffer)}
          onExecute={onTableCommonAction}
          isFetchingSelector={getIsOfferProcessingSelector(tradeOffer.id)}
        />
      )}
    />
  );
};

export default TradeOfferTable;
